@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4a4a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
  overflow: hidden;
}

.error-message {
  display: block;
  font-weight: bold;
  color: #9c1e25;
  margin-top: 1px;
  margin-bottom: 25px;
  font-size: 12px;
}

.required-flag {
  font-weight: bold;
  font-size: 16px;
  color: #9c1e25;
}

.ant-btn:active,
.ant-btn:hover,
.ant-btn:focus {
  color: rgba(0, 0, 0, 0.75);
  border-color: #c9c9c9;
}

.ant-btn-primary {
  background: #9c1e25;
  border: 1px solid #9c1e25;
  color: white;
  outline: none;
  font-weight: bold;
}
.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: white;
  background: #6b1419;
  border: 1px solid #9c1e25;
  outline: none;
}
